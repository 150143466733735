// 01. COMMON ANIMATION
//------------------------------------------------------------------------------------
@keyframes fadeIn{
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes shrink-v{
  0% {
    height: 0;
  }
  50% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}
@keyframes shrink-h{
  0% {
    width: 0;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}

@keyframes shrink{
  0% {
    opacity: 0;
    transform: scale(0);
    transform-origin: left bottom;
  }
  50% {
    opacity: 0;
    transform: scale(0.5);
    transform-origin: left bottom;
  }
  100% {
    opacity: 1;
    transform: scale(1);
    transform-origin: left bottom;
  }
}



// 02. LOADING
//------------------------------------------------------------------------------------
@keyframes loadingbar {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
  }
}



// 03. KV
//------------------------------------------------------------------------------------
@keyframes kv-left {
  0% {
    opacity: 0;
    bottom: -100px;
    left: -30%;
  }
  100% {
    opacity: 0.8;
    bottom: 0;
    left: 0;
  }
}

@keyframes kv-left-sp {
  0% {
    opacity: 0;
    bottom: -50px;
    left: -30%;
  }
  100% {
    opacity: 0.8;
    bottom: 0;
    left: 0;
  }
}


@keyframes kv-right {
  0% {
    opacity: 0;
    right: -100%;
    bottom: -250px;
  }
  100% {
    opacity: 1;
    right: 0;
    bottom: 0;
  }
}


@keyframes kv-right-sp {
  0% {
    opacity: 0;
    right: -100%;
    bottom: -100px;
  }
  100% {
    opacity: 1;
    right: 0;
    bottom: 0;
  }
}



// 04. GNAV MENU ICON
//------------------------------------------------------------------------------------
@keyframes trigger-icon{
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-90deg);
    top: 35%;
    right: 27%;
  }
}
@keyframes trigger-line-top{
  from {
    top: 10%;
    opacity: 1;
  }
  to {
    top: 45%;
    opacity: 0;
  }
}
@keyframes trigger-line-mid{
  from {
    top: 45%;
    opacity: 1;
  }
  to {
    top: 10%;
    opacity: 0;
  }
}
@keyframes trigger-line-btm{
  from {
    top: 80%;
    opacity: 1;
  }
  to {
    top: 10%;
    opacity: 0;
  }
}


// 05. COUPON DEMO
//------------------------------------------------------------------------------------
@keyframes sp-vibe{
  0%{
    transform: rotate(0deg);
  }
  5%{
    transform: rotate(2deg);
  }
  10%{
    transform: rotate(-2deg);
  }
  20%{
    transform: rotate(2deg);
  }
  30%{
    transform: rotate(-2deg);
  }
  40%{
    transform: rotate(2deg);
  }
  50%{
    transform: rotate(-2deg);
  }
}

@keyframes move-left{
  0%{
    transform: translateX(-1em);
    opacity: 0;
  }

  100%{
    transform: translateX(0);
    opacity: 1;
  }
}