// 01. BUTTON
//------------------------------------------------------------------------------------
.btn-scrolltop{
  display: block;
  opacity: 0;

  &.is-active{
    opacity: 1;
  }
}

.btn-trigger{
  display: none;

  @media #{$sp} {
    display: block;
    @include hamburger( 
      $width: 30px,
      $height: 30px,
      $bg: $base-color,
      $color: #fff  );
  }
}

.btn-default{
  @include btn-radius;
}

.btn-simple{
  @include btn-radius( $bg: white, $border: $tx-color , $text: $tx-color, $type: white );

  &.thin{
    min-width: 250px;
    a{
      padding: 0.5em 1em;
    }
  }

  &.right{
    float: right;
  }
}

.btn-back{
  @include btn-radius( $bg: white, $border: $tx-color , $text: $tx-color, $type: back );
}