// prefix [u-]

// 01. UTILITIES
//------------------------------------------------------------------------------------
.u-dropshadow{
  filter: drop-shadow( 1px 1px 4px black);
}

.u-break{
  display: inline-block;
}

.u-bg-gray{
  overflow: hidden;
  background: lighten( $gray , 15% );
}

.u-bg-lightgray{
  overflow: hidden;
  background: lighten( $gray , 18% );
}


// 02. IMAGE ALIGNMENT
//------------------------------------------------------------------------------------
//.u-img-right{
//  display: block;
//  margin-left: auto;
//  
//  @media #{$sp}{
//    margin-right: auto;
//  }
//}
//
//.u-img-left{
//  display: block;
//  margin-right: auto;
//  
//  @media #{$sp}{
//    margin-left: auto;
//  }
//}
//
//.u-img-center{
//  display: block;
//  margin: {
//    left: auto;
//    right: auto;
//  }
//}
//
//.u-block{
//  display: block;
//}
//
//.u-inlineblock{
//  display: inline-block;
//}

.u-figure{
  figcaption{
    padding: 1em;
    font-size: $fontsize - 0.1em;
  }
}



// 03. WIDTH
//------------------------------------------------------------------------------------
// .u-w10, .u-w20 ...
$widthparam: (
  '70' : 70%,
  '50' : 50%
  //'7em' : 7em
);

@each $key, $param in $widthparam {
  .u-w#{$key}{
    width: $param;
    
    @media #{$sp}{
      width: 100%;
    }
  }
}



// 04. MARGIN , PADDING
//------------------------------------------------------------------------------------
// .u-mt0 , .u-mt10 ...
$marginparam: (
	mt20: (
    direction: "top",
    value:     2em
	),
	mt40: (
    direction: "top",
    value:     4em
	),
	mb15: (
    direction: "bottom",
    value:     1.5em
	),
);

@each $key, $param in $marginparam{
	.u-#{$key} {
		margin-#{map-get($param, direction)}: map-get($param, value)!important;
	}
}

// .u-mt0 , .u-mt10 ...
$paddingparam: (
	pb0: (
    direction: "bottom",
    value:     0
	)
);

@each $key, $param in $paddingparam{
	.u-#{$key} {
		padding-#{map-get($param, direction)}: map-get($param, value)!important;
	}
}