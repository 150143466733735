


// 03. LIST
//------------------------------------------------------------------------------------
ul{
  &.icon-list > li{
    margin-bottom:0.5em;
    
    &::before{
      content: "●";
      position: absolute;
      margin: {
        top: $fontsize - 0.2em;
        left: -1.7em;
      }
      font-size: 0.5em;
      color: $base-color;
    }
  }

  &.icon-list li{
    position: relative;
    padding-left: 1em;
    
      & > ul,
      & > ol{
        margin-top: 0.5em;
      }
    }

  & li:last-of-type{
    margin-bottom: 0;
  }
}

ol{
  &.decimal-list > li{
    margin-bottom: 0.5em;
    list-style: decimal;
  }

  li{
    position: relative;
    margin: {
      left: 1.5em;
    }

    &:last-of-type{
      margin-bottom: 0;
    }
    
    & > ul,
    & > ol{
      margin-top: 0.5em;
    }
  }
}



// 01. TABLE
//------------------------------------------------------------------------------------
table{

  width: 100%;
  font-size: $fontsize;
  border-collapse: collapse;
  border: 2px solid $gray;
  @media #{$sp}{
    font-size: $fontsize - 0.15em;
  }
  
  th,td{
    padding: 1em;
    border-collapse: collapse;
    border: 1px solid $gray;
    
    @media #{$sp}{
      padding: 0.5em;
    }
  }

  th{
    text-align: left;
    font-weight: 500;
    background: lighten( $gray , 15% );

    &.emp{
      color: white;
      background: $base-color;
    }
  }

  td{
    &.emp{
      font-weight: 500;
      background: lighten( $base-color , 50% );
    }
  }
}



// 01. DEFINITION LIST
//------------------------------------------------------------------------------------
dl{
  &.default-dl{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    
    @media #{$sp}{
      display: block;
    }
      
    dt,dd{
      padding: 1.5em 0;
      border-bottom: 1px solid $gray;
      
      @media #{$sp}{
        padding: 0;
      }
    }
    
    dt{
      width: 30%;
      font-weight: 500;
      
      @media #{$sp}{
        padding: 1em 0 0.5em;
        border-bottom: none;
        width: 100%;
      }
    }
    dd{
      width: 70%;
      padding-left: 2em;
      
      @media #{$sp}{
        width: 100%;
        padding-bottom: 1em;
        padding-left: 0;
      }
    }
  }
}



// 01. ENTRY STYLE
//------------------------------------------------------------------------------------
.entry{ // entry class

  overflow: hidden;
  
  pre{
    white-space: pre-wrap;
  }

  h1,h2,h3,h4,h5,h6{
    margin: 1.5em 0 0.5em;
    padding-bottom: 0.3em;
    border-bottom: 1px solid lighten( $gray , 10% );
    font-weight: 500;
    letter-spacing: .05em;
  }
  
  p{
    margin-bottom: 0.5em;
    @include paragraph;
  }
  
  a{
    text-decoration: underline;
  }
  
  & > ul{
    margin-bottom: 1em;
    @extend .icon-list;
    @include paragraph;
  }

  & > ol{
    margin-bottom: 1em;
    @extend .decimal-list;
    @include paragraph;
  }

  table{    
    margin: 1em 0;
    @include paragraph;
    
    th{
      background: lighten( $base-color , 35% );
      font-weight: 500;
    }
  }
  
  dl{
    @extend .default-dl;
  }
  
  strong { font-weight: bold; }
  em { font-style: italic; }
  blockquote {
      display: block;
      -webkit-margin-before: 1em;
      -webkit-margin-after: 1em;
      -webkit-margin-start: 40px;
      -webkit-margin-end: 40px;
  }
  
  .aligncenter {
      display: block;
      margin: 0 auto;
  }
  .alignright { float: right; }
  .alignleft { float: left; }

  img[class*="wp-image-"],
  img[class*="attachment-"] {
      height: auto;
      max-width: 100%;
  }

  .clearfix {
      overflow: hidden;
      zoom: 1;
  }
  .clearfix:after {
      content: "";
      display: block;
      clear: both;
  }
  
  .wp-caption{
    p{
      margin-bottom: 2em;
      font-size: $fontsize - 0.2em;
      line-height: 1.2;
    }
  }
}



// 05. IMG , FIGURE
//------------------------------------------------------------------------------------  
img{
  max-width: 100%;
  height: auto;
}