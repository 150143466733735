// 01. FORM
//------------------------------------------------------------------------------------
select,
textarea,
input[type="text"],
input[type="password"],
input[type="submit"]{
  border: 1px solid $tx-color;
}

textarea,
input[type="text"]{
  padding: 0.3em 0.5em;
  width: 100%;
}

input[type="radio"],
input[type="checkbox"],
.input-label{
  display: inline-block;
  margin-right: 0.3em;
}

input{
  &.w40{
    width: 40%;
  }
  &.w50{
    width: 50%;
  }
}

textarea{
  height: 15em;
}

::placeholder{
  font-size: 0.75em;
  color: darken( $gray , 30% );
}

.form-item{
  display: flex;
  padding: 1em 0;

  &.error{
    background: lighten( $pink , 36% );
  }

  &__title{
    flex-basis: 25%;
    font-weight: 500;

    @media #{$sp}{
      margin-bottom: 0.5em;
    }
  }

  &__body{
    flex-basis: 75%;
  }

  @media #{$sp}{
    display: block;
  }

  .input-area{
    margin: 0.8em 0;
    display: block;

    &:nth-of-type(1){
        margin: 0 0 0.8em;
    }
  }
}

.privacy-check{
  margin: 1em auto 0;
  padding: 2em 0;
  width: 100%;
  max-width: 400px;
  text-align: center;
  background: lighten( $base-color , 54% );

  &.error{
    background: lighten( $pink , 36% );
  }
}

.error-notice{
  display: block;
  font-size: 0.9em;
  color: $pink;

  &::before{
    content: "※ ";
  }
}