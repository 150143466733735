// 01. HEADER MODULES
//------------------------------------------------------------------------------------
.gHead{
    margin: 0 auto;
    width: $container-width;

    &__logo{
        padding: 1em;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        width: 18vw;
        background: white;
        @include fitimg-w;

        @media #{$sp}{
            width: 20vw;
            min-width: 120px;
        }
    }

    &__nav{
        padding: 1.5em 0;

        @media #{$sp}{
            padding: 0;
        }
    }

    &__btn{
        @media #{$pc,$tb}{
            display: none;
        }

        position: fixed;
        top: 0;
        right: 0;
        z-index: 200;
        padding: 0.8em 1em;
        background: $base-color;
    }
}



// 02. GLOBAL NAV
//------------------------------------------------------------------------------------
.gNav{

    display: flex;
    justify-content: flex-end;
    font-size: $fontsize - 0.2em;
    font-weight: 500;
    
    @media #{$sp}{
        opacity: 0;
        transform: translateY(-1em);
        transition: all .3s;
        position: fixed;
        top: 50px;
        right: 0;
        z-index: 2000;
        font-size: $fontsize;
        flex-direction: column;
        width: 60vw;
        background: $base-color;
    }
    
    &.is_sp{
        opacity: 1;
        transform: translateY(0);
    }
    
    li{
        margin: 0 1.5em;
    
        a{
            display: block;
            transition: all .3s;

            @media #{$pc,tb}{
                &:hover{
                    color: $base-color;
                }
            }

            @media #{$sp}{
                display: none;
                padding: 1em;
                color: white;
            }
        }

        @at-root .gNav.is_sp a{
            display: block!important;
        }
        
        &:last-of-type{
            margin: 0 0 0 1.5em;
        }

        @media #{$sp}{
            margin: 0!important;
            border-bottom: 1px solid darken( $base-color , 5% );

            &:last-of-type{
                border-bottom: none;
            }
        }
    }
}