// 01. VALUES
//------------------------------------------------------------------------------------

// Media Query
$pc: "screen and (min-width: 1100px)";
$tb: "screen and (min-width: 768px) and (max-width: 1100px)";
$sp: "screen and (max-width: 767px)";

$blue:         #0097e1;
$pink:         #e95550;
$gray:         #cccccc;

$base-color:   $blue!default;
$accent-color: $pink!default;
$tx-color:     #111111;

// layout
$container-width: 88%;

// Font
$fontsize:     0.95em;