// 01. THEMES
//------------------------------------------------------------------------------------

.top-lead{
    .lead-tx{
        font-weight: 500;
        font-size: 1.2em;
        text-align: center;
        letter-spacing: .05em;
    }

    .movie-block{
        position: relative;
        margin: 2em auto 0;
        padding-bottom: 56.25%; /* アスペクト比16:9の縦幅 */
        overflow: hidden;
    
        iframe{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.about{

    margin-top: 2em;
    
    @media #{$tb,$sp}{
        flex-wrap: wrap;
    }

    @media #{$sp}{
        display: block;
    }

    p{
        @include paragraph;
    }
    
    &__img{
        position: relative;
        flex-basis: 45%;
        background: url('../images/bg-parking.jpg') no-repeat;
        background-size: cover;
    
        @media #{$sp}{
            display: none;
        }

        .img-mobile{
            width: 50%;
            position: absolute;
            right: -2em;
            bottom: -2.5em;
            @include fitimg-w;
            background: white;
        }
    }
    
    &__desc{
        flex-basis: 50%;
    }
}

.howto{
    margin: 2em 0;
    position: relative;
    
    &__nav{
        position: sticky;
        top: 2em;
        display: flex;
        flex-flow: column nowrap;
        
        &__item{
            
            $size:   3em;
            $bar-size: 2em;
            
            position: relative;
            display: block;
            width: $size;
            height: $size;
            margin-bottom: $bar-size;
            border-radius: $size / 2;
            border: 1px solid $base-color;
            text-align: center;
            font-size: $fontsize;
            line-height: $size;
            font-weight: 700;
            letter-spacing: 0.1em;
            color: $base-color;
            
            &::after{
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                display: block;
                content: "";
                margin-top: -1px;
                width: 1px;
                height: $bar-size;
                background: lighten( $base-color , 30% )
            }
            
            &:last-of-type{
                &::after{
                    content: none;
                }
            }
            
            &.is_active{
                color: white;
                background-color: $base-color;
            }
        }
    }
    
    &__main{
        width: 80%;
        margin-left: 5em;
    }
    
    &__body{
        margin-bottom: 3em;
        padding: 2em;
        min-height: 300px; //debug
        border: 1px solid lighten( $gray , 10% );
        border-radius: 0.3em;
        
        &:last-of-type{
            margin-bottom: 0;
        }
    }
}

.news{
    .news-list{
        li{
            margin: 0 0 2em;
            @include paragraph;
        }
    }

    .date{
        display: inline-block;
        width: 8em;

        @media #{$sp}{
            display: block;
        }
    }
}

.cases{
    display: flex;
    justify-content: start;
    flex-wrap: wrap;

    @media #{$sp}{
        justify-content: space-between;
    }

    &__item{
        flex-basis: calc( 100% / 3 );
        margin-bottom: 2em;
        padding: 0 1.5%;

        @media #{$sp}{
            flex-basis: calc( 100% / 2 );
        }
    }

    p{
        @include paragraph;
    }
}

.faq{

    .faq-title{
        margin: 2em 0 1em;
        font-weight: 500;

        &:first-of-type{
            margin-top: 0;
        }
    }

    p{
        @include paragraph;
        margin-bottom: 1.5em;
    }
}

.tech{

    position: relative;

    &__detail{
        margin: 2em 0;
        padding: 1.5em;
        width: 70%;
        max-width: 800px;
        background: lighten( $gray , 15% );
        @include cf;

        @media #{$sp}{
            margin-top: 0;
            width: 100%;
            max-width: auto;
        }
    }

    &__title{
        margin-bottom: 0.5em;
        font-weight: 500;
    }

    &__body{
        display: flex;
        justify-content: space-between;

        @media #{$sp}{
            flex-flow: column;
        }

        .desc{
            flex-basis: 65%;

            @media #{$sp}{
                margin-bottom: 1em;
            }
        }

        .img{
            flex-basis: 30%;
            text-align: center;
        }
    }

    &__slide{
        position: absolute;
        right: 0;
        bottom: 0;
        width: 30%;

        @media #{$sp}{
            position: static;
            margin: 0 auto;
            width: 70%;
        }

        img{
            display: block;
            margin-left: auto;
            width: 90%;
            max-width: 280px;

            @media #{$sp}{
                margin: 0 auto;
            }
        }
    }

    p{
        @include paragraph;
    }
}

.spec{
    p{
        @include paragraph;
        margin-bottom: 2em;
    }

    table{
        th,td{
            width: calc( 100% / 4 );
            text-align: center;
            border-left: none;
            border-right: none;
        }
    }
}

.enquete{

    p{
        @include paragraph;
    }

    @media #{$sp}{
        flex-flow: column!important;
    }

    &__desc{
        flex-basis: 60%;
    }

    &__img{
        flex-basis: 35%;

        @media #{$sp}{
            display: flex;
            justify-content: space-between;
            margin: 1em 0 0;
        }

        .graph{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1em;

            @media #{$sp}{
                flex-flow: column;
                justify-content: flex-start;
                margin-bottom: 0;
                width: 30%;
            }
        }
    }
}

.about-sec{

    @media #{$tb,$sp}{
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    &__desc{
        flex-basis: 45%;

        @media #{$sp}{
            flex-basis: 100%;
        }
    }
    
    &__coupon{
        padding-top: 5em;
        max-width: 600px;
    
        @media #{$tb}{
            margin: 0 auto 2em;
        }

        @media #{$sp}{
            margin: 0 auto 2em;
            padding-top: 3em;
            min-width: auto;
            width: 320px;
        }
    }

    .lightbox-displaynone{
        display: none;
    }
}

.coupon{
    position: relative;
    overflow: visible;

    img{
        opacity: 0;
    }

    &__mall{
        opacity: 1!important;
        position: static;
    }

    &__rt{
        position: absolute;
        top: -7em;
        left: 2.5em;
        display: flex;
        align-items: baseline;

        @media #{$sp}{
            top: -5em;
            transform: scale(0.6);
            transform-origin: left top;
        }

        .car1{
            margin-right: 2em;
            &.is-animate{
                animation: move-left 1s ease forwards;
            }
        }
    
        .human1{
            margin-right: 0.5em;
            &.is-animate{
                animation: move-left 1s ease .5s forwards;
            }
        }
        
        .sp1{
            &.is-animate{
                animation:  move-left 1s ease .5s forwards,
                            sp-vibe 1s ease 1.5s infinite normal;
            }
        }
    
        .popup1{
            margin-left: 1em;
            &.is-animate{
                animation: shrink 1s ease-in 1.2s forwards;
            }
        }
    }

    &__sec{       
        position: absolute;
        top: 4.5em;
        left: 2.5em;
        display: flex;
        align-items: baseline;

        @media #{$sp}{
            top: 2em;
            transform: scale(0.6);
            transform-origin: left top;
        }
        
        .car2{
            margin-right: 2em;
            &.is-animate{
                animation: move-left 1s ease .5s forwards;
            }
        }
    
        .human2{
            margin-right: 0.5em;
            &.is-animate{
                animation: move-left 1s ease 1s forwards;
            }
        }
    
        .sp2{
            &.is-animate{
                animation:  move-left 1s ease 1s forwards,
                            sp-vibe 1s ease 2s infinite normal;
            }
        }
    
        .popup2{
            margin-left: 1em;
            &.is-animate{
                animation: shrink 1s ease-in 1.7s forwards;
            }
        }
    }
}
