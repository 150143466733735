// prefix [l-]

// 00. BODY WRAP
//------------------------------------------------------------------------------------
.l-wrap{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.l-pageload {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background: white;
  z-index: 1;
  
  .page-loader {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 2;
  }
}

.l-gHead{
  position: relative;
  width: 100vw;
  background: white;
}

.l-gFoot{
  margin-top: auto;
  padding: 2em 0;
  background: lighten( $base-color , 54% );
}

.l-scrolltop{
  position: fixed;
  right: 2em;
  bottom: 1.5em;
  z-index: 1;
}

.l-lead{
  @include container($margin-key: thin);
  max-width: 600px;
}

.l-kv{
  position: relative;
  width: 100vw;
  background: url("../images/kv.jpg") no-repeat;
  background-size: cover;

  &.index{
    height: 80vh;
    background-position: center -10vw;
    overflow: hidden;

    @media #{$tb}{
      height: 45vw;
      min-height: 230px;
      background-position: center center;
    }

    @media #{$sp}{
      height: 70vh;
      background-position: center bottom;
    }
  }

  &.page{
    height: 45vh;
    background-position: center -15vw;

    @media #{$tb,$sp}{
      height: 30vw;
      min-height: 230px;
      background-position: center center;
    }
  }
}

.l-content{

  &.wide{
    @include container($margin-key: wide);
  }

  &.thin{
    @include container($margin-key: wide);
    max-width: 1100px;
  }
}

.l-left-title{
  &__title{
    float: left;
    margin-left: 8%!important;

    @media #{$tb, $sp}{
      float: none;
      margin-left: auto!important;
    }
  }

  &__body{
    margin-left: auto;
    width: 65%;

    @media #{$tb,$sp}{
      margin-top: 2em;
      width: 100%;
    }
  }
}

.l-entrywrap{
  @include cf;
  margin: 0 0 4em;
}

.l-form{
  margin-top: 3em;

  .l-privacy{
    margin: 1em auto;
    width: 100%;
    max-width: 920px;
  }
}

.l-btn-container{
  margin: 6em auto;
  max-width: 960px;
  display: flex;
  justify-content: space-around;

  @media #{$sp}{
    margin: 2em auto;
    width: 92%;
    flex-flow: column;
  }
  
  & > *{
    @media #{$sp}{
      margin: 0 0 1em;
    }
  }
}

.l-col2{
  display: flex;
  justify-content: space-between;

  &.reverse{
    flex-direction: row-reverse;

    @media #{$sp}{
      flex-direction: column-reverse;
    }
  }

  &__item{
    flex-basis: 50%;
  }
}