// 01. FOOTER MODULES
//------------------------------------------------------------------------------------
.gFoot{
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: $container-width;
    font-size: $fontsize - 0.2em;

    @media #{$sp}{
        display: block;
    }

    &__logo{
        flex-basis: 150px;
        @include fitimg-w;
        
        @media #{$sp}{
            margin: 0 auto;
            width: 30%;
        }
    }


    &__nav{
        flex-basis: 400px;
        display: flex;

        a{
            display: block;
            transition: all .3s;
            fill: $tx-color;
            
            &:hover{
                color: $base-color;
                fill: $base-color;
            }
        }
    }

    &__rights{

        font-size: $fontsize - 0.2em;

        p{
            @media #{$sp}{
                margin-top: 2em;
                text-align: center;
            }
        }
    }
}



// 02. FOOTER NAVI
//------------------------------------------------------------------------------------
.footer-gNav{
    width: 50%;
    font-weight: 500;

    @media #{$sp}{
        font-size: $fontsize;
    }

    li{
        padding: 1em 0;
    }
}

.footer-subNav{
    display: flex;
    font-weight: 500;

    @media #{$sp} {
        width: 100%;
        justify-content: center;
    }

    li{
        margin-right: 2em;
    }
}