// 02. NOTO SANS SUBSETS MIXIN
//------------------------------------------------------------------------------------
@mixin font-face($name, $path, $weight: null, $exts: woff2 woff otf) {
	$src: null;

	$formats: (
		woff2: "woff2",
		woff: "woff",
		otf: "otf"
	);

	@each $ext in $exts {
		$format: map-get($formats, $ext);
		$src: append($src, url(quote($path + '.' + $ext)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style:  normal;
		font-weight: $weight;
		src: $src;
	}
}

@mixin cf{
	&:after{
		visibility: hidden;
		display: block;
		font-size: 0;
		content: " ";
		clear: both;
		height: 0;
	}
}

@mixin paragraph( $align: left , $line-h: 1.6 ){
  font-size: $fontsize;
  line-height: $line-h;
  text-align: $align;
  letter-spacing: .05em;
  word-break: break-all;
  word-wrap: break-word;
  
  @media #{$sp}{
    //font-size: $fontsize - 0.1em;
  }
}

@mixin text-link( $color: $blue ){
  color: $color;
  transition: .3s;
  cursor: pointer;
  text-decoration: none;

  &:hover{
    color: lighten( $color , 15% );
  }
}

@mixin outline-text( $color: #fff ){
  text-shadow: 1px 1px 2px $color,
               2px 2px 2px $color,
               -1px -1px 2px $color,
               -2px -2px 2px $color,
               -1px 0 2px $color,
               -2px 0 2px $color,
               0 -1px 2px $color,
               0 -2px 2px $color,
               1px 0 2px $color,
               2px 0 2px $color,
               0 1px 2px $color,
               0 2px 2px $color,
               1px 2px 2px $color,
               2px 1px 2px $color,
               -1px -2px 2px $color,
               -2px -1px 2px $color;
}

@mixin hamburger( $width: 25px, $height: 30px, $bg: none, $color: #fff  ){

  position: relative;
  width: $width;
  height: $height;
  background: $bg;
  border: none;
  appearance: none;
  cursor: pointer;
  
  &__icon{
    position: absolute;
    display: inline-block;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: $color;
    border-radius: 4px;
    transition: all .3s;
    box-sizing: border-box;  
    
    &:nth-of-type(1){
      top: 0px;
    }

    &:nth-of-type(2){
      top: $height / 2 - 2px;
    }

    &:nth-of-type(3){
      bottom: 0;
    }
  }
  
  .is_active &__icon{
    
    &:nth-of-type(1){
      transform: translateY(#{$height / 2 - 2px}) rotate(-45deg);
    }

    &:nth-of-type(2){
      opacity: 0;
    }

    &:nth-of-type(3){
      transform: translateY(-#{$height / 2 - 2px}) rotate(45deg);
    }
  }
}

@mixin container( $margin-key: fit , $padding-key: fit ) {

	$margin: (
		fit: 0 auto,
		thin: 2em auto 1.5em,
		medium: 5em auto 3.5em,
		wide: 5em auto,
	);
	$sp-margin: (
		fit: 0 auto,
		thin: 2em auto 1.5em,
		medium: 3em auto,
		wide: 3em auto,
	);
	
	$padding:(
		fit: 0,
		thin: 0.5em 0,
		medium: 1em 0,
		wide: 2em 0,
	);

	margin: map-get( $margin , $margin-key );
	padding: map-get( $padding , $padding-key );
	max-width: 1500px;
	width: $container-width;
  @include cf;

  @media #{$sp}{
    margin: map-get( $sp-margin , $margin-key );
  }
}

@mixin archive-thumb( $height: 220px ){
  //box-size
  border: 1px solid $gray;
  width: 100%;
  aspect-ratio: 4 / 2.3;
  overflow: hidden;
  line-height: 0;
  
  //thumb
  img{
    width: 100%;
    aspect-ratio: 4 / 2.3;
    object-fit: cover;
    transition: all .3s;
  }
  
  &:hover{
    img{
      transform: scale(1.1);
    }
  }
}

@mixin btn-radius(
  $bg: $base-color,
  $border: $base-color,
  $text: white,
  $type: blue ){

  position: relative;
  display: inline-block;
  min-width: 360px;
  vertical-align: middle;

  @media #{$sp}{
    min-width: auto;
  }

  @if $type == blue { // type blue
    &::before{
      position: absolute;
      left: 1em;
      top: 50%;
      transform: translateY(-40%);
      content: url( 'data:image/svg+xml;utf-8,<svg fill="%23fff" xmlns="http://www.w3.org/2000/svg" width="16.867" height="24.363" viewBox="0 0 16.867 24.363"><path d="M16.308,3.375c-4.656,0-8.433,3.508-8.433,7.83,0,6.091,8.433,16.533,8.433,16.533S24.742,17.3,24.742,11.205C24.742,6.883,20.964,3.375,16.308,3.375Zm0,11.18a2.747,2.747,0,1,1,2.747-2.747A2.747,2.747,0,0,1,16.308,14.555Z" transform="translate(-7.875 -3.375)"/></svg>' );
    }
  
    &::after{
      position: absolute;
      right: 1em;
      top: 50%;
      transform: translateY(-50%);
      transition: all .3s;
      content: url( 'data:image/svg+xml;utf-8,<svg fill="%23fff" xmlns="http://www.w3.org/2000/svg" width="17" height="16.726" viewBox="0 0 17 16.726"><path d="M12,7.5V24.226l17-8.363Z" transform="translate(-12 -7.5)"/></svg>' );
    }

    &:hover{
      &::after{
        right: 0.5em;
      }
    }
  } @else if $type == white { // type white
    &::before{
      position: absolute;
      left: 1em;
      top: 50%;
      transform: translateY(-40%);
      content: url( 'data:image/svg+xml;utf-8,<svg fill="%23111" xmlns="http://www.w3.org/2000/svg" width="16.867" height="24.363" viewBox="0 0 16.867 24.363"><path d="M16.308,3.375c-4.656,0-8.433,3.508-8.433,7.83,0,6.091,8.433,16.533,8.433,16.533S24.742,17.3,24.742,11.205C24.742,6.883,20.964,3.375,16.308,3.375Zm0,11.18a2.747,2.747,0,1,1,2.747-2.747A2.747,2.747,0,0,1,16.308,14.555Z" transform="translate(-7.875 -3.375)"/></svg>' );
    }
  
    &::after{
      position: absolute;
      right: 1em;
      top: 50%;
      transform: translateY(-50%);
      transition: all .3s;
      content: url( 'data:image/svg+xml;utf-8,<svg fill="%23111" xmlns="http://www.w3.org/2000/svg" width="17" height="16.726" viewBox="0 0 17 16.726"><path d="M12,7.5V24.226l17-8.363Z" transform="translate(-12 -7.5)"/></svg>' );
    }

    &:hover{
      &::after{
        right: 0.5em;
      }
    }
  } @else if $type == back { // type back

    &::before{
      position: absolute;
      left: 1em;
      top: 50%;
      transition: all .3s;
      transform: translateY(-50%) rotate(-180deg);
      content: url( 'data:image/svg+xml;utf-8,<svg fill="%23111" xmlns="http://www.w3.org/2000/svg" width="17" height="16.726" viewBox="0 0 17 16.726"><path d="M12,7.5V24.226l17-8.363Z" transform="translate(-12 -7.5)"/></svg>' );
    }

    &:hover{
      &::before{
        left: 0.5em;
      }
    }
  }

  a,input{
    display: block;
    padding: 1em 3em;
    width: 100%;
    font-size: $fontsize;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.08em;
    color: $text;
    border-radius: 3px;
    border: 1px solid $border;
    background: $bg;
    text-decoration: none!important;
  }
}

@mixin fitimg-w{
  img{
    width: 100%;
    height: auto;
    @content;
  }
}

@mixin fitimg-h{
  img{
    width: auto;
    max-height: 100%;
  }
}
