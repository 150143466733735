// 01. KV
//------------------------------------------------------------------------------------
.kv-lead{
    position: absolute;
    left: 0.5em;
    bottom: 0.5em;
    z-index: 1;
    font-size: 4em;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0.15em;
    color: $tx-color;
    white-space: nowrap;

    @media #{$sp}{
        bottom: 2em;
    }

    .lead-cap{
        display: block;
        margin-top: 0.5em;
        font-weight: 700;
        font-size: 0.35em;
        letter-spacing: 0.08em;
        color: darken( $gray , 60% );
    }

    @media #{$sp}{
        transform: scale(0.6);
        transform-origin: left bottom;
    }
}

.kv-ribbon{

    $left-width: 44vw;

    display: flex;
    width: 100vw;
    height: 100%;
    overflow: hidden;

    &__left{
        opacity: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        width: $left-width;
        height: 100%;
        background: url(../images/kv-line-l.svg) no-repeat;
        background-position: left bottom;
        background-size: 100%;
        animation: kv-left .7s ease 1.2s forwards;

        @media #{$sp}{
            background-position: left center;
            animation: kv-left-sp .7s ease .8s forwards;
        }
    }
    
    &__right{
        opacity: 0;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100vw - $left-width;
        height: 100%;
        background: url(../images/kv-line-r.svg) no-repeat;
        background-position: 0 bottom;
        background-size: 100%;
        animation: kv-right 1s ease .5s forwards;

        @media #{$sp}{
            background-position: 0 center;
            animation: kv-right-sp 1s ease .5s forwards;
        }
    }
}

.kv-rover{
    position: absolute;
    bottom: 1.2em;
    right: 2em;
    z-index: 1;
    opacity: 0;
    width: 200px;
    animation:fadeIn .7s ease 1.2s normal forwards ;

    @media #{$sp}{
        left: 2em;
    }
}



// 02. TITLE
//------------------------------------------------------------------------------------
.page-title{
    position: absolute;
    bottom: -1em;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.8em 1.5em;
    background: url("../images/bg-pagetitle.svg") no-repeat;
    background-size: cover;

    @media #{$pc,$tb}{
        min-width: 500px;
    }

    font-size: $fontsize + 1em;
    font-weight: 900;
    letter-spacing: 0.08em;
    color: white;
    line-height: 1;
    text-align: center;

    @media #{$tb,$sp}{
        font-size: $fontsize + 0.7em;
    }

    .jp-cap{
        display: block;
        margin-top: 1em;
        font-weight: 400;
        font-size: 0.5em;
        white-space: nowrap;
    }
}

.content-title{
    position: relative;
    display: table;
    margin: 0 auto;
    font-size: $fontsize + 1.4em;
    font-weight: 900;
    letter-spacing: 0.08em;
    line-height: 1.6;
    text-align: center;

    @media #{$sp}{
        font-size: $fontsize + 1em;
    }

    .jp-cap{
        display: block;
        font-weight: 400;
        font-size: 0.5em;
    }

    &::before{
        position: absolute;
        top: 0.85em;
        left: -1.5em;
        display: block;
        content: "";
        width: 1.2em;
        height: 1px;
        background: $tx-color;
    }

    &::after{
        position: absolute;
        top: 0.85em;
        right: -1.5em;
        display: block;
        content: "";
        width: 1.2em;
        height: 1px;
        background: $tx-color;
    }
}

.content-title-sec{
    margin-bottom: 1.5em;
    font-weight: 700;
    letter-spacing: 0.07em;
}

.content-title-3rd{
    margin-bottom: 1em;
    font-weight: 700;
    letter-spacing: 0.07em;
}

.content-title-num{
    position: relative;
    margin-bottom: 0.5em;
    padding-left: 1.5em;
    font-size: $fontsize + 0.8em;
    font-weight: 700;
    text-align: justify;
    letter-spacing: 0.1em;

    @media #{$sp}{
        font-size: $fontsize + 0.5em;
    }

    .icn-num{
        position: absolute;
        left: 0;
        display: block;
        width: 1.4em;
        height: 2em;
        text-align: center;
        font-size: 0.7em;
        color: $base-color;
        border-bottom: 3px solid $base-color;
    }
}

.content-title-step{
    position: relative;
    margin-bottom: 0.5em;
    font-size: $fontsize + 0.8em;
    font-weight: 700;
    text-align: justify;
    letter-spacing: 0.1em;

    @media #{$sp}{
        font-size: $fontsize + 0.5em;
    }

    .icn-step{
        display: block;
        width: 1.4em;
        text-align: center;
        color: $base-color;

        &::before{
            display: block;
            content: "step";
            font-weight: 400;
            font-size: 0.6em;
            letter-spacing: normal;
        }
    }
}

.news-title{
    @extend .content-title-sec;
    border-bottom: 1px solid lighten( $gray , 10% );

    .date{
        display: block;
        margin-top: 0.5em;
        padding-bottom: 0.5em;
        font-size: $fontsize - 0.3em;
        text-align: right;
        font-weight: 500;
    }
}