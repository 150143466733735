// 02. BODY
//------------------------------------------------------------------------------------
// FLUID TYPOGRAPHY PARAM
// MinFontSize[px] + ( MaxFontSize - MinFontSize ) * ( (100vw - MinViewportSize[px] ) / ( MaxViewportsize - MinViewportSize )); 

@include font-face('Noto Sans Japanese', '../font/NotoSans_thin', 100, woff2 woff);
@include font-face('Noto Sans Japanese', '../font/NotoSans_light', 300, woff2 woff);
@include font-face('Noto Sans Japanese', '../font/NotoSans_regular', 400, woff2 woff);
@include font-face('Noto Sans Japanese', '../font/NotoSans_medium', 500, woff2 woff);
@include font-face('Noto Sans Japanese', '../font/NotoSans_bold', 700, woff2 woff);
@include font-face('Noto Sans Japanese', '../font/NotoSans_black', 900, woff2 woff);

body{
	font-family: Noto Sans Japanese;
  font-weight: 400;
  font-size: calc(1em + (1 - 0.2) * ((100vw - 767px) / (1100 - 767)));
  color: $tx-color;
}



// 01. PAGE LOADING
//------------------------------------------------------------------------------------
//#loader {
//  position: fixed;
//    width: 100%;
//    height: 100vh;
//    background: white;
//    z-index: 10000;
//}
//
//// 読み込み完了後に「loaded」を付与する
//#loader.loaded {
//    animation: fadeOut 0.5s forwards;
//    z-index: -1;
//}



// 02. HEADLINE , PARAGRAPH
//------------------------------------------------------------------------------------


a{
  @include text-link( $color: $tx-color );
}

a.txt-link{
  @include text-link( $color: $blue );
}