// prefix [tx-]

// 01. TEXT COLOR
//------------------------------------------------------------------------------------
$txcolor: (
  'blue'       : $base-color
);

@each $key, $param in $txcolor {
  .tx-#{$key}{
    color: $param;
  }
}



// 02. DECORATION
//------------------------------------------------------------------------------------
.tx-bold{
  font-weight: 700!important;
}

.tx-semibold{
  font-weight: 500!important;
}

.tx-underline{
  text-decoration: underline;
}



// 03. ALIGN
//------------------------------------------------------------------------------------
.tx-center{
  text-align: center!important;
}

.tx-left{
  text-align: left!important;
}

.tx-right{
  text-align: right!important;
}



// 04. SIZE
//------------------------------------------------------------------------------------
.tx-xl{
  font-size: $fontsize + 0.3em!important;
}

.tx-small{
  font-size: $fontsize - 0.1em!important;
}

.tx-xs{
  font-size: $fontsize - 0.3em!important;
}