// prefix [icn-]

// 01. ICON
//------------------------------------------------------------------------------------
.icn-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate( -50% , -50% );
  width: 50px;
  height: 40px;
  text-align: center;
  
  .rect{
    display: block;
    height: 80px;
    width: 6px;
    display: inline-block;
    animation: loadingbar 1.2s infinite ease-in-out;
    
    &:nth-of-type(1){
      animation-delay: -1.1s;
      background: $base-color;
    }  
    &:nth-of-type(2){
      animation-delay: -1.0s;
      background: $base-color;
    }
    &:nth-of-type(3){
      animation-delay: -0.9s;
      background: $base-color;
    }
    &:nth-of-type(4){
      animation-delay: -0.8s;
      background: $base-color;
    }
  }
}


@mixin faq-icon( $content: "Q" , $bg: $base-color , $font: white ){
  &::before{
    display: inline-block;
    margin-right: 0.5em;
    margin-bottom: 0.5em;
    width: 1.8em;
    height: 1.8em;
    content: $content;
    border: 1px solid $base-color;
    background: $bg;
    font-weight: 500;
    text-align: center;
    line-height: 1.8em;
    color: $font;
    border-radius: 0.2em;
  }
}
.icn-q{
  @include faq-icon;
  color: $base-color;
}

.icn-a{
  @include faq-icon( $content: "A" , $bg: white , $font:$base-color );
}